import React from 'react';
import Seo from '../components/Seo/Seo'
import { StaticImage } from 'gatsby-plugin-image'
import {Layout} from '../components/Layout';

export default function Portfolio() {
  return <Layout>
    <Seo title="Portfolio" />
    <div className="max-w-7xl h-full mx-auto px-6 mb-10 md:px-0 md:py-28 flex flex-col justify-between">
      <h1 className="text-3xl text-center mb-12">Zaufali nam</h1>
      <div className="w-full place-items-center grid grid-cols-none md:grid-cols-3 gap-4 md:gap-8">
        <a href="https://artispro.pl/" target="_blank" rel="noreferrer" className="w-full sm:w-5/6 h-48 rounded-lg px-6 md:px-0 py-2 cursor-pointer hover:drop-shadow-lg bg-white transition flex items-center justify-center">
          <StaticImage src="../images/clients/artispro.png" className="w-3/4 object-contain" alt="artispro.pl" />
        </a>
        
        <a href="https://www.precio.pl/" target="_blank" rel="noreferrer" className="w-full sm:w-5/6 h-48 rounded-lg px-12 md:px-0 py-2 cursor-pointer hover:drop-shadow-lg bg-white transition flex items-center justify-center">
            <StaticImage src="../images/clients/precio.png" className="w-3/4 object-contain" alt="precio.pl" />
        </a>
        <a href="https://drew-haus.pl/" target="_blank" rel="noreferrer" className="w-full sm:w-5/6 h-48 rounded-lg px-6 md:px-0 py-2 cursor-pointer hover:drop-shadow-lg bg-white transition flex items-center justify-center">
            <StaticImage src="../images/clients/drewhaus.png" className="w-1/2 md:w-2/3 object-contain" alt="drew-haus.pl"/>
        </a>
      </div>
    </div>
  </Layout>
}